import { request } from "@/core/services/Request";
import Error from "@/core/services/Error";

const DEFAULT_FORM_STATE = {
  name: null,
  email: null,
  profile: {},
  phone: null,
  parent_user_id: undefined,
  role: undefined,
  _method: 'post',
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Name",
    sortable: false,
    value: 'name',
  },
  {
    text: "Role",
    sortable: false,
    value: 'roles',
  },
  {
    text: "Email",
    sortable: false,
    value: 'email',
  },
  {
    text: "Phone",
    sortable: false,
    value: 'phone',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];

export default {
  data() {
    return {
      operationTitle: 'Users',
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      listUrl: '/users',
      columns: COLUMN_DEFINATION(this),
      dropdowns: {
        roles: [],
        superUsers: [],
        masterGuestUser: [],
        events: [],
        allRoles: [],
      },
      file: {},
    };
  },
  methods: {
    async onChangeFile(e) {
      this.file = await this.uploadFile(e.target.files[0]);
    },
    async uploadFile(file) {
      let formData = new FormData();
      formData.append('file', file);

      try {
        const response = await request({
          url: '/upload',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        const { data } = response.data;
        return data;

      } catch (error) {
      }
    },
    afterCloseOperation() {
      this.formFields = { ...DEFAULT_FORM_STATE };
    },
    handleChangeOperations(operation = 'add', operationDataId = null) {
      this.$router.replace({
        query: Object.assign({},
          this.$route.query,
          {
            ...this.listQueryParams,
            operation: operation,
            id: operationDataId,
          },
        ),
      });
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      let self = this;
      try {
        const response = await request({
          url: this.formFields.id ? 'users/update' : 'users/create',
          method: 'post',
          data: this.formFields,
        });
        const { data } = response.data;
        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }

        this.handleOperationClose();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      if ( confirm('are you sure?') ) {
        try {
          const response = await request({
            method: 'post',
            url: '/users/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/users/detail/${ id }`,
        });
        const { data } = response.data;
        this.formFields = {
          ...data,
          role: data.roles.map(item => item.id),
        };
        this.file = data.profile_pic;
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async getRoles() {
      try {
        const response = await request({
          url: '/dropdowns/roles',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.roles = _.filter(_.map(data, function (item) {
          if ( _.includes(["superuser", "subuser", "masterguestuser", "subguestuser"], _.toLower(item.name)) ) {
            return {
              id: item.id,
              label: item.name,
            };
          }
        }), (i) => {
          return i != null;
        });

        this.dropdowns.allRoles = _.map(data, (item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getSuperUsers() {
      try {
        const response = await request({
          url: '/dropdowns/super/user',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.superUsers = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getMasterGuestUser() {
      try {
        const response = await request({
          url: '/dropdowns/master/guest/user',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.masterGuestUser = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getEvents(userId) {
      try {
        const response = await request({
          url: '/dropdowns/events',
          method: 'post',
          data: {
            filter: {
              user_id: userId
            }
          }
        });

        const { data } = response.data;
        this.dropdowns.events = data.map((item) => {
          return {
            id: item.id,
            label: item.label,
          };
        });
      } catch (e) {

      }
    },
    handleOperationClose() {
      this.formFields = { ...DEFAULT_FORM_STATE };
      this.$router.push({ name: "userList" });
    },
  },
  computed: {
    getFile() {
      return this.file;
    }
  }
};
